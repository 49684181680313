<template>
  <div class="container">
    <div class="container content">
      <!-- <v-alert
        text
        class="gepi-alert"
        color="cyan darken-2"
        icon="mdi-alert-circle"
      >
        The data in GEPI.io is currently incomplete. We will integrate
        additional data sources and build new GEPI.io features as the Centers
        continue to reimagine internal data standards and processes.
      </v-alert> -->
      <div class="search-type">
        <div class="project-icon-box">
          <div class="project-icon"></div>
        </div>
        <h2>Projects</h2>
      </div>
      <projects-card
        :searchFilter="true"
        :resultsFilter="true"
        :orderingFilter="true"
        :completedFilter="true"
        :centerFilter="true"
        :completed="false"
      />
    </div>
  </div>
</template>
<style>
/* Page CSS */
.project-icon {
  height: 55px;
  width: 52px;
  margin: 0px auto;
  padding: 10px;
  background-color: white;
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  mask-image: url("../assets/icons/projects.svg");
}

.project-icon-box {
  margin: 15px 10px;
  border-radius: 10px;
  height: 70px;
  width: 70px;
  padding-top: 7px;
  max-height: 140px;
  max-width: 140px;
  background-color: var(--aqua);
}

.input-box {
  width: 90%;
}
label[for="search"] {
  height: 25px;
}
#search {
  position: relative;
  top: -6px;
}
</style>
<script>
import axios from "axios";
import ProjectsCard from "../components/ProjectsCard";
export default {
  components: {
    ProjectsCard,
  },
  title() {
    return `Projects - GEPI.io`;
  },
  data() {
    return {};
  },
};
</script>
